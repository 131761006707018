<template>
  <div>
    <v-form @submit.prevent="onBeforeSubmit" ref="formRef">

      <v-row dense class="pt-4 pb-0 mb-0">
         <div class="center-text h1"> Equipamento: {{currentItem.equipamento.equipamento.nome}} {{ currentItem.equipamento.nomecomercial  ? ` - "${currentItem.equipamento.nomecomercial}" ` : ''}} - Cód.: {{currentItem.equipamento.codigo}} - Id: {{currentItem.id}}
          </div>
      </v-row>
      <v-row dense>
        <v-col>
          <v-select outlined required label="Status" v-model="currentItem.ativo" item-value="value"
            item-text="nome" :items="ativoOptions"></v-select>
        </v-col> 
        <v-col v-if="currentItem.aditivo_equipamento">
          <v-select outlined required label="Aditivo" v-model="currentItem.aditivo_equipamento" item-value="value"
            item-text="nome" :items="statusOptions" disabled></v-select>
        </v-col> 
        <v-col  v-if="currentItem.aditivo_equipamento">
          <v-text-field label="Nr do Aditivo" outlined v-model="currentItem.nr_aditivo" type="Number"  disabled> </v-text-field>
        </v-col>
        <v-col  v-if="currentItem.aditivo_equipamento">
          <v-text-field label="Data do Aditivo" outlined v-model="currentItem.data_aditivo" type="date"  disabled> </v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Data de Início" outlined v-model="formattedDataInicio" type="date" > </v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Data de Término" outlined v-model="formattedDataFim" type="date" > </v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-text-field label="Quantidade" outlined v-model="currentItem.quantidade_equipamento" type="Number" >
          </v-text-field>
        </v-col> 
        <v-col>
          <vuetify-money outlined label="Valor cobrança" v-model="currentItem.valor_cobranca_equipamento" :options="options" disabled>
          </vuetify-money>
        </v-col>
        <v-col>
          <v-text-field label="Desconto (%)" outlined v-model="currentItem.desconto_equipamento"  >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Acréscimo (%)" outlined v-model="currentItem.acrescimo_equipamento"  >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Total" outlined v-model="procedureTotalValue" disabled></v-text-field>
        </v-col>
         <v-col v-if="currentItem.sessao.realizarrecursoglosa">
          <v-select outlined required label="Recurso Glosa" v-model="currentItem.recurso_glosa_equipamento" item-value="value"
            item-text="nome" :items="statusOptions"></v-select>
        </v-col>
        <v-col v-if="currentItem.sessao.cobranca_parcial">
         <v-select outlined required label="Realizar Cobrança Parcial" v-model="currentItem.cobranca_parcial" item-value="value" item-text="nome" :items="statusOptions"></v-select>
         </v-col>
      </v-row>
      <v-row dense v-if="currentItem.recurso_glosa_equipamento && currentItem.sessao.realizarrecursoglosa">
         <v-col cols="2">
          <v-text-field label="Sequência do Item " outlined v-model="currentItem.sequeItemGlosa_equipamento" type="Number"></v-text-field>
        </v-col>
        <v-col cols="2">
         <v-text-field label="Data da Execução" outlined v-model="currentItem.data_execucao_glosa_equipamento" type="Date" >
          </v-text-field>
        </v-col>
        <v-col  cols="2">
          <v-autocomplete 
          outlined 
          label="Código do Recurso de Glosa" 
          v-model="currentItem.motivoglosa_equipamento" 
          :items="codigorecurso" 
          item-value="id" 
          :item-text="(item) => `${item.codigo} - ${item.nome.replace(/\n/g, ' ')}`" 
          @change="updateMotivoGlosa"
          ></v-autocomplete>
        </v-col>
        <v-col  cols="4">
          <v-text-field label="Justificativa da Glosa " outlined v-model="currentItem.justificativarecursoglosa_equipamento"></v-text-field>
        </v-col>
        <v-col cols="2">
          <vuetify-money outlined label="Valor Recurso Glosa" v-model="currentItem.valor_glosa_equipamento" :options="options" :rules="requiredField">
          </vuetify-money>
        </v-col>
      </v-row>
      <div dense v-if="currentItem.sessao.cobranca_parcial">
        <!-- Linha 1 -->
        <v-row dense v-if="currentItem.cobranca_parcial">
          <v-col >
            <v-select :items="cobrancaParcialOptions" label="Nº 1ª da Cobrança Parcial" outlined v-model="currentItem.n_cp_1" clearable ></v-select>
          </v-col>
          <v-col>
            <v-text-field label="Data de Início da 1ª Cobrança" outlined v-model="formattedDataInicioCP1" type="date" clearable @blur="validateDates('inicioCP1')" :error-messages="errorMessages.inicioCP1"> </v-text-field>
           
          </v-col>
          <v-col>
            <v-text-field label="Data de Término 1ª Cobrança" outlined v-model="formattedDataFimCP1" type="date" clearable @blur="validateDates('fimCP1')" :error-messages="errorMessages.fimCP1"> </v-text-field>
            <!-- Exibir mensagem de erro se a data for inválida -->
            
          </v-col>
          <v-col>
          <v-text-field label="Quantidade da 1ª Cobrança" outlined v-model="currentItem.qtd_cp_1" type="Number" clearable>  </v-text-field>
          </v-col> 
          <v-col cols="1" >
            <v-text-field label="Saldo" :value="saldo" outlined disabled >  </v-text-field>
          </v-col>
        </v-row>
        <!-- Linha 2 -->
        <v-row dense v-if="(currentItem.n_cp_1 > 0 && currentItem.data_fim_cp_1 && currentItem.data_fim_cp_1 && currentItem.qtd_cp_1) && (currentItem.qtd_cp_1 < currentItem.quantidade_equipamento)">
          <v-col >
            <v-select :items="cobrancaParcialOptions" label="Nº 1ª da Cobrança Parcial" outlined v-model="currentItem.n_cp_2" clearable ></v-select>
          </v-col>
          <v-col >
            <v-text-field label="Data de Início da 2ª Cobrança" outlined v-model="formattedDataInicioCP2" type="date" clearable @blur="validateDates('inicioCP2')" :error-messages="errorMessages.inicioCP2"> </v-text-field>
          </v-col>
          <v-col >
            <v-text-field label="Data de Término 2ª Cobrança" outlined v-model="formattedDataFimCP2" type="date" clearable @blur="validateDates('fimCP2')" :error-messages="errorMessages.fimCP2"> </v-text-field>
          </v-col>
          <v-col >
          <v-text-field label="Quantidade da 2ª Cobrança" outlined v-model="currentItem.qtd_cp_2" type="Number" clearable >  </v-text-field>
          </v-col> 
          <v-col cols="1" >
            <v-text-field label="Saldo" :value="saldo" outlined disabled >  </v-text-field>
          </v-col>
        </v-row>
        <!-- Linha 3 -->
        <v-row dense v-if="(currentItem.n_cp_2 > 0 && currentItem.data_fim_cp_2 && currentItem.data_fim_cp_2 && currentItem.qtd_cp_2) && ( currentItem.quantidade_equipamento > (Number(currentItem.qtd_cp_1) + Number(currentItem.qtd_cp_2)))">
          <v-col >
            <v-select :items="cobrancaParcialOptions" label="Nº 1ª da Cobrança Parcial" outlined v-model="currentItem.n_cp_3" clearable ></v-select>
          </v-col>
          <v-col>
            <v-text-field label="Data de Início da 3ª Cobrança" outlined v-model="formattedDataInicioCP3" type="date" clearable @blur="validateDates('inicioCP3')" :error-messages="errorMessages.inicioCP3"> </v-text-field>
          </v-col>
          <v-col>
            <v-text-field label="Data de Término 3ª Cobrança" outlined v-model="formattedDataFimCP3" type="date"  clearable @blur="validateDates('fimCP3')" :error-messages="errorMessages.fimCP3"> </v-text-field>
          </v-col>
          <v-col>
          <v-text-field label="Quantidade da 3ª Cobrança" outlined v-model="currentItem.qtd_cp_3" type="Number" clearable >  </v-text-field>
          </v-col> 
           <v-col cols="1" >
            <v-text-field label="Saldo" :value="saldo" outlined disabled >  </v-text-field>
          </v-col>
        </v-row>
        <!-- Linha 4 -->
        <v-row dense v-if="(currentItem.n_cp_3 > 0 && currentItem.data_fim_cp_3 && currentItem.data_fim_cp_3 && currentItem.qtd_cp_3) && ( currentItem.quantidade_equipamento > (Number(currentItem.qtd_cp_1) + Number(currentItem.qtd_cp_2) + Number(currentItem.qtd_cp_3)))">
          <v-col >
            <v-select :items="cobrancaParcialOptions" label="Nº 1ª da Cobrança Parcial" outlined v-model="currentItem.n_cp_4" clearable ></v-select>
          </v-col>
          <v-col>
            <v-text-field label="Data de Início da 4ª Cobrança" outlined v-model="formattedDataInicioCP4" type="date"  clearable @blur="validateDates('inicioCP4')" :error-messages="errorMessages.inicioCP4"> </v-text-field>
          </v-col>
          <v-col>
            <v-text-field label="Data de Término 4ª Cobrança" outlined v-model="formattedDataFimCP4" type="date"  clearable @blur="validateDates('fimCP4')" :error-messages="errorMessages.fimCP4"> </v-text-field>
          </v-col>
          <v-col>
          <v-text-field label="Quantidade da 4ª Cobrança" outlined v-model="currentItem.qtd_cp_4" type="Number" clearable>  </v-text-field>
          </v-col> 
           <v-col cols="1" >
            <v-text-field label="Saldo" :value="saldo" outlined disabled >  </v-text-field>
          </v-col>
        </v-row>
      </div>
      <v-row dense>
        <v-col class="text-end">
          <ConfirmButton color="success" :loading="loading">
            Salvar
          </ConfirmButton>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
  
<script>
import ConfirmButton from '../../ConfirmButton.vue';
import api from "../../../http";
import '../../../assets/css/styles.css';



export default {
  name: 'ProcedureFormEquipamento',
  components: { ConfirmButton },
  props: {
    onSubmit: Function,
    currentItem: Object,
    orcamennto: Object,
    statusOptions: Array,
    ativoOptions: Array,
    loading: Boolean
  },
  data() {
    return {
    options: {
      locale: "pt-BR",
      prefix: "R$",
      suffix: "",
      length: 11,
      precision: 2
    },
    codigorecurso: [], // Aqui está agora no nível certo para ser reativo
    selectedRecursoGlosa: null,
    currencyMask: {
      decimal: ',',
      thousands: '.',
      precision: 2,
    },
    cobrancaParcialOptions: this.getCobrancaParcialOptions(),
    requiredField: [e => e !== undefined  && e !== null && e!== '' || 'Obrigatório'],
    errorMessages: {
        inicioCP1: '',
        fimCP1: '',
        inicioCP2: '',
        fimCP2: '',
        inicioCP3: '',
        fimCP3: '',
        inicioCP4: '',
        fimCP4: '',
      },
  }},
  methods: {
    getCobrancaParcialOptions() {
      // Cria uma array com os valores de n_cp_1 a n_cp_4 que não são nulos
      const options = [
        this.orcamennto.n_cp_1,
        this.orcamennto.n_cp_2,
        this.orcamennto.n_cp_3,
        this.orcamennto.n_cp_4
      ].filter(value => value !== null);
      return options;
    },
    setDatesForSelectedPartial() {
      // Itera de 1 a 4 para as cobranças parciais
      for (let i = 1; i <= 4; i++) {
        const selectedPartial = this.currentItem[`n_cp_${i}`];

        // Verifica cada cobrança parcial e define as datas de início e fim correspondentes
        for (let j = 1; j <= 4; j++) {
          if (selectedPartial === this.orcamennto[`n_cp_${j}`]) {
            this.currentItem[`data_inicio_cp_${i}`] = this.orcamennto[`data_inicio_cp_${j}`];
            this.currentItem[`data_fim_cp_${i}`] = this.orcamennto[`data_fim_cp_${j}`];
            this.calculateDays(i);
            break; // Interrompe o loop interno se a cobrança correspondente for encontrada
          }
        }
      }
    },
    calculateDays(cpIndex) {
      const startDate = this.currentItem[`data_inicio_cp_${cpIndex}`];
      const endDate = this.currentItem[`data_fim_cp_${cpIndex}`];

      // Verifica se ambas as datas estão preenchidas
      if (startDate && endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        if (!isNaN(start) && !isNaN(end) && start <= end) {
          const diffDays = Math.ceil((end - start) / (1000 * 3600 * 24)) + 1;
          this.currentItem[`qtd_cp_${cpIndex}`] = diffDays;
        } else {
          this.currentItem[`qtd_cp_${cpIndex}`] = 0;
        }
      } else {
        // Define a quantidade como null se as datas não estiverem preenchidas
        this.currentItem[`qtd_cp_${cpIndex}`] = null;
      }
    },
    formatDateForBackend(date) {
      if (!date) return null;
      // Verifique se a data já está no formato correto (sem duplicar o 'T00:00:00')
      if (date.includes('T')) {
        return date;  // Retorne a data se já estiver no formato correto
      }
      // Caso contrário, formate como 'YYYY-MM-DDT00:00:00'
      return `${date}T00:00:00`;
    },
    formatDateForFrontend(datetime) {
      if (!datetime) return '';
      return datetime.split('T')[0]; // Retorna apenas a parte da data
    },
    onBeforeSubmit() {
      const validation = this.$refs.formRef.validate()
      // Campos de data para validação antes de salvar
      const fieldsToValidate = ['inicioCP1', 'fimCP1', 'inicioCP2', 'fimCP2', 'inicioCP3', 'fimCP3', 'inicioCP4', 'fimCP4'];

      // Executa a validação em cada campo de data
      fieldsToValidate.forEach(field => {
        this.validateDates(field);
      });
      if (!validation) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios!");
        return;
      }

      // Validação do saldo antes de permitir o envio do formulário
      if (!this.validateSaldo()) {
        return; // Bloqueia o envio se o saldo for negativo
      }

      // Verifica se há mensagens de erro nas datas
      const hasDateErrors = Object.values(this.errorMessages).some(message => message !== '');

      if (!validation || hasDateErrors) {
        this.$toast.error("Por favor, corrija todos os campos obrigatórios e erros antes de enviar o formulário!");
        return;
      }

      // Validação específica dos campos 'n_cp_1', 'data_inicio_cp_1', 'data_fim_cp_1', e 'qtd_cp_1'
      if (this.currentItem.n_cp_1) {
        if (!this.currentItem.data_inicio_cp_1 || !this.currentItem.data_fim_cp_1 || !this.currentItem.qtd_cp_1) {
          this.$toast.error("Por favor, preencha a data de início, data de término e quantidade da 1ª Cobrança quando o Nº 1ª da Cobrança estiver preenchido.");
          return;
        }
      }

      // Repetir a validação para 'n_cp_2', 'n_cp_3', e 'n_cp_4' se necessário
      if (this.currentItem.n_cp_2) {
        if (!this.currentItem.data_inicio_cp_2 || !this.currentItem.data_fim_cp_2 || !this.currentItem.qtd_cp_2) {
          this.$toast.error("Por favor, preencha a data de início, data de término e quantidade da 2ª Cobrança quando o Nº 2ª da Cobrança estiver preenchido.");
          return;
        }
      }

      if (this.currentItem.n_cp_3) {
        if (!this.currentItem.data_inicio_cp_3 || !this.currentItem.data_fim_cp_3 || !this.currentItem.qtd_cp_3) {
          this.$toast.error("Por favor, preencha a data de início, data de término e quantidade da 3ª Cobrança quando o Nº 3ª da Cobrança estiver preenchido.");
          return;
        }
      }

      if (this.currentItem.n_cp_4) {
        if (!this.currentItem.data_inicio_cp_4 || !this.currentItem.data_fim_cp_4 || !this.currentItem.qtd_cp_4) {
          this.$toast.error("Por favor, preencha a data de início, data de término e quantidade da 4ª Cobrança quando o Nº 4ª da Cobrança estiver preenchido.");
          return;
        }
      }
          // Função para substituir vírgula por ponto e garantir duas casas decimais
      const formatNumber = (value) => {
      if (value === null || value === undefined || value === '') {
        return 0;
      }
      if (typeof value === 'string') {
        value = value.replace(',', '.');
        return parseFloat(value).toFixed(2);
      }
      return value;
    };

      // Formatar os valores de desconto e acréscimo
      const desconto = formatNumber(this.currentItem.desconto_equipamento);
      const acrescimo = formatNumber(this.currentItem.acrescimo_equipamento);

      let valorCobrancaFinal = this.procedureTotalValue;

      // Verificar se valorCobrancaFinal é uma string antes de chamar replace
      if (typeof valorCobrancaFinal === 'string') {
        valorCobrancaFinal = parseFloat(valorCobrancaFinal.replace('R$', '').replace('.', '').replace(',', '.'));
      } else if (typeof valorCobrancaFinal === 'number') {
        valorCobrancaFinal = parseFloat(valorCobrancaFinal.toFixed(2)); // Certificar-se de que é um número com 2 casas decimais
      } else {
        valorCobrancaFinal = 0; // Definir um valor padrão em caso de tipo inesperado
      }

      const fields = {
        id: this.currentItem.id,
        ativo: Boolean(this.currentItem.ativo),
        data_inicio_equipamento: (this.currentItem.data_inicio_equipamento),
        data_fim_equipamento: (this.currentItem.data_fim_equipamento),
        quantidade_equipamento: Number(this.currentItem.quantidade_equipamento),
        desconto_equipamento: desconto,
        acrescimo_equipamento: acrescimo,
        valor_cobranca_final_equipamento: valorCobrancaFinal,
        motivoglosa_equipamento: this.currentItem.motivoglosa_equipamento,
        recurso_glosa_equipamento: Boolean(this.currentItem.recurso_glosa_equipamento),
        justificativarecursoglosa_equipamento: this.currentItem.justificativarecursoglosa_equipamento,
        sequeItemGlosa_equipamento: this.currentItem.sequeItemGlosa_equipamento ? this.currentItem.sequeItemGlosa_equipamento : null,
        data_execucao_glosa_equipamento: this.currentItem.data_execucao_glosa_equipamento ? this.currentItem.data_execucao_glosa_equipamento : null,
        valor_glosa_equipamento: this.currentItem.valor_glosa_equipamento ? Number(this.currentItem.valor_glosa_equipamento) : null,
        cobranca_parcial: this.currentItem.cobranca_parcial ? Boolean(this.currentItem.cobranca_parcial) : null,
        n_cp_1: this.currentItem.n_cp_1 ? Number(this.currentItem.n_cp_1) : null,
        n_cp_2: this.currentItem.n_cp_2 ? Number(this.currentItem.n_cp_2) : null,
        n_cp_3: this.currentItem.n_cp_3 ? Number(this.currentItem.n_cp_3) : null,
        n_cp_4: this.currentItem.n_cp_4 ? Number(this.currentItem.n_cp_4) : null,
        data_inicio_cp_1: this.formatDateForBackend(this.currentItem.data_inicio_cp_1),
        data_fim_cp_1: this.formatDateForBackend(this.currentItem.data_fim_cp_1),
        data_inicio_cp_2: this.formatDateForBackend(this.currentItem.data_inicio_cp_2),
        data_fim_cp_2: this.formatDateForBackend(this.currentItem.data_fim_cp_2),
        data_inicio_cp_3: this.formatDateForBackend(this.currentItem.data_inicio_cp_3),
        data_fim_cp_3: this.formatDateForBackend(this.currentItem.data_fim_cp_3),
        data_inicio_cp_4: this.formatDateForBackend(this.currentItem.data_inicio_cp_4),
        data_fim_cp_4: this.formatDateForBackend(this.currentItem.data_fim_cp_4),
        qtd_cp_1: this.currentItem.qtd_cp_1 ? Number(this.currentItem.qtd_cp_1) : null,
        qtd_cp_2: this.currentItem.qtd_cp_2 ? Number(this.currentItem.qtd_cp_2) : null,
        qtd_cp_3: this.currentItem.qtd_cp_3 ? Number(this.currentItem.qtd_cp_3) : null,
        qtd_cp_4: this.currentItem.qtd_cp_4 ? Number(this.currentItem.qtd_cp_4) : null,
      }
      
      if (this.currentItem.aditivo_equipamento) {
        fields.nr_aditivo = this.currentItem.nr_aditivo;
        fields.data_aditivo = this.currentItem.data_aditivo;
      }

      // console.log(fields)
      this.onSubmit(fields)
    },
    calculateQuantity() {
        const startDate = new Date(this.currentItem.data_inicio_equipamento.split('T')[0]);
        const endDate = new Date(this.currentItem.data_fim_equipamento.split('T')[0]);
        if (!isNaN(startDate) && !isNaN(endDate)) {
            // Calcula a diferença em dias entre as duas datas
            const timeDiff = endDate.getTime() - startDate.getTime();
            const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
            this.currentItem.quantidade_equipamento = diffDays;
        } else {
            this.currentItem.quantidade_equipamento = 0;
        }
    },
    async getCodigoRecurso() {
      try {
        const { data } = await api.get('uteis/motivoglosa/');
        this.codigorecurso = data.filter(item => item.ativo);
        // console.log('Código Recurso:', this.codigorecurso); // Verifique os dados aqui
      } catch (error) {
        alert("Erro ao listar os recursos de glosa");
        console.log(error);
      }
     },
     updateMotivoGlosa(selectedId) {
      // Encontra o item selecionado pelo ID
      const selectedRecurso = this.codigorecurso.find(item => item.id === selectedId);
      
      // Atualiza o campo motivoglosa com o nome do recurso de glosa selecionado
      if (selectedRecurso) {
        this.currentItem.motivoglosa_equipamento = selectedRecurso.id;
        // console.log('Motivo da Glosa Atualizado:', this.currentItem.motivoglosa);
      }
    },
    validateSaldo() {
      const saldo = this.saldo;
      if (saldo < 0) {
        this.$toast.error("A soma das cobranças parciais não pode ser maior que a quantidade total.");
        return false;
      }
      return true;
    },
    validateSaldoOnInput() {
      const saldo = this.saldo;
      if (saldo < 0) {
        this.$toast.error("A soma das cobranças parciais não pode ser maior que a quantidade total.");
      }
    },
    validateDates(field) {
      const startDateOrcamento = new Date(this.formattedDataInicio);
      const endDateOrcamento = new Date(this.formattedDataFim);

      let dateToValidate;
      let errorMessage = '';

      switch (field) {
        case 'inicioCP1':
          dateToValidate = new Date(this.formattedDataInicioCP1);
          if (dateToValidate < startDateOrcamento) {
            errorMessage = 'Data de início não pode ser menor que a data do início do orçamento.';
          }
          break;
        case 'fimCP1':
          dateToValidate = new Date(this.formattedDataFimCP1);
          if (dateToValidate > endDateOrcamento) {
            errorMessage = 'Data de término não pode ser maior que a data de término do orçamento.';
          }
          break;
        case 'inicioCP2':
          dateToValidate = new Date(this.formattedDataInicioCP2);
          if (dateToValidate < startDateOrcamento) {
            errorMessage = 'Data de início não pode ser menor que a data do início do orçamento.';
          }
          break;
        case 'fimCP2':
          dateToValidate = new Date(this.formattedDataFimCP2);
          if (dateToValidate > endDateOrcamento) {
            errorMessage = 'Data de término não pode ser maior que a data de término do orçamento.';
          }
          break;
        case 'inicioCP3':
          dateToValidate = new Date(this.formattedDataInicioCP3);
          if (dateToValidate < startDateOrcamento) {
            errorMessage = 'Data de início não pode ser menor que a data do início do orçamento.';
          }
          break;
        case 'fimCP3':
          dateToValidate = new Date(this.formattedDataFimCP3);
          if (dateToValidate > endDateOrcamento) {
            errorMessage = 'Data de término não pode ser maior que a data de término do orçamento.';
          }
          break;
        case 'inicioCP4':
          dateToValidate = new Date(this.formattedDataInicioCP4);
          if (dateToValidate < startDateOrcamento) {
            errorMessage = 'Data de início não pode ser menor que a data do início do orçamento.';
          }
          break;
        case 'fimCP4':
          dateToValidate = new Date(this.formattedDataFimCP4);
          if (dateToValidate > endDateOrcamento) {
            errorMessage = 'Data de término não pode ser maior que a data de término do orçamento.';
          }
          break;
      }
      // Atualiza a mensagem de erro para o campo específico
      this.$set(this.errorMessages, field, errorMessage);
    },
  },
  computed: {
    formattedDataInicio: {
      get() {
        return this.currentItem.data_inicio_equipamento ? this.currentItem.data_inicio_equipamento.split('T')[0] : '';
      },
      set(newValue) {
        this.currentItem.data_inicio_equipamento = newValue;
      }
    },
    formattedDataFim: {
      get() {
        return this.currentItem.data_fim_equipamento ? this.currentItem.data_fim_equipamento.split('T')[0] : '';
      },
      set(newValue) {
        this.currentItem.data_fim_equipamento = newValue;
      }
    },
    procedureTotalValue() {
     const basePrice = parseFloat(this.currentItem.valor_cobranca_equipamento);
      const discount = this.currentItem.desconto_equipamento ? parseFloat(this.currentItem.desconto_equipamento) / 100 : 0;
      const addition = this.currentItem.acrescimo_equipamento ? parseFloat(this.currentItem.acrescimo_equipamento) / 100 : 0;
      const adjustedPrice = (basePrice * (1 + addition - discount)) * this.currentItem.quantidade_equipamento;
      return `R$ ${adjustedPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    },
    formattedDataInicioCP1: {
      get() {
        return this.formatDateForFrontend(this.currentItem.data_inicio_cp_1);
      },
      set(newValue) {
        this.currentItem.data_inicio_cp_1 = newValue;
      }
    },
    formattedDataFimCP1: {
      get() {
        return this.formatDateForFrontend(this.currentItem.data_fim_cp_1);
      },
      set(newValue) {
        this.currentItem.data_fim_cp_1 = newValue;
      }
    },
    formattedDataInicioCP2: {
      get() {
        return this.formatDateForFrontend(this.currentItem.data_inicio_cp_2);
      },
      set(newValue) {
        this.currentItem.data_inicio_cp_2 = newValue;
      }
    },
    formattedDataFimCP2: {
      get() {
        return this.formatDateForFrontend(this.currentItem.data_fim_cp_2);
      },
      set(newValue) {
        this.currentItem.data_fim_cp_2 = newValue;
      }
    },
    formattedDataInicioCP3: {
      get() {
        return this.formatDateForFrontend(this.currentItem.data_inicio_cp_3);
      },
      set(newValue) {
        this.currentItem.data_inicio_cp_3 = newValue;
      }
    },
    formattedDataFimCP3: {
      get() {
        return this.formatDateForFrontend(this.currentItem.data_fim_cp_3);
      },
      set(newValue) {
        this.currentItem.data_fim_cp_3 = newValue;
      }
    },
    formattedDataInicioCP4: {
      get() {
        return this.formatDateForFrontend(this.currentItem.data_inicio_cp_4);
      },
      set(newValue) {
        this.currentItem.data_inicio_cp_4 = newValue;
      }
    },
    formattedDataFimCP4: {
      get() {
        return this.formatDateForFrontend(this.currentItem.data_fim_cp_4);
      },
      set(newValue) {
        this.currentItem.data_fim_cp_4 = newValue;
      }
    },
    saldo() {
      const quantidadeEquipamento = Number(this.currentItem.quantidade_equipamento) || 0;
      const qtdCp1 = Number(this.currentItem.qtd_cp_1) || 0;
      const qtdCp2 = Number(this.currentItem.qtd_cp_2) || 0;
      const qtdCp3 = Number(this.currentItem.qtd_cp_3) || 0;
      const qtdCp4 = Number(this.currentItem.qtd_cp_4) || 0;
      const totalCp = qtdCp1 + qtdCp2 + qtdCp3 + qtdCp4;

      return quantidadeEquipamento - totalCp;
    }
  },
   watch: {
    'currentItem.data_inicio_equipamento': 'calculateQuantity',
    'currentItem.data_fim_equipamento': 'calculateQuantity',
    'currentItem.qtd_cp_1': 'validateSaldoOnInput',
    'currentItem.qtd_cp_2': 'validateSaldoOnInput',
    'currentItem.qtd_cp_3': 'validateSaldoOnInput',
    'currentItem.qtd_cp_4': 'validateSaldoOnInput',
    'currentItem.cobranca_parcial': function(newVal) {
      if (!newVal) {
        this.currentItem.qtd_cp_1 = null;
        this.currentItem.qtd_cp_2 = null;
        this.currentItem.qtd_cp_3 = null;
        this.currentItem.qtd_cp_4 = null;
        this.currentItem.n_cp_1 = null;
        this.currentItem.n_cp_2 = null;
        this.currentItem.n_cp_3 = null;
        this.currentItem.n_cp_4 = null;
        this.currentItem.data_inicio_cp_1 = null;
        this.currentItem.data_fim_cp_1 = null;
        this.currentItem.data_inicio_cp_2 = null;
        this.currentItem.data_fim_cp_2 = null;
        this.currentItem.data_inicio_cp_3 = null;
        this.currentItem.data_fim_cp_3 = null;
        this.currentItem.data_inicio_cp_4 = null;
        this.currentItem.data_fim_cp_4 = null;
      }
    },
    'currentItem.n_cp_1': function() {
      this.setDatesForSelectedPartial();
    },
    'currentItem.n_cp_2': function() {
      this.setDatesForSelectedPartial();
    },
    'currentItem.n_cp_3': function() {
      this.setDatesForSelectedPartial();
    },
    'currentItem.n_cp_4': function() {
      this.setDatesForSelectedPartial();
    },
    'currentItem.data_inicio_cp_1': function() { this.calculateDays(1); },
    'currentItem.data_fim_cp_1': function() { this.calculateDays(1); },
    'currentItem.data_inicio_cp_2': function() { this.calculateDays(2); },
    'currentItem.data_fim_cp_2': function() { this.calculateDays(2); },
    'currentItem.data_inicio_cp_3': function() { this.calculateDays(3); },
    'currentItem.data_fim_cp_3': function() { this.calculateDays(3); },
    'currentItem.data_inicio_cp_4': function() { this.calculateDays(4); },
    'currentItem.data_fim_cp_4': function() { this.calculateDays(4); },
  },
   mounted() {
    this.getCodigoRecurso();
  }
}
</script>
  
<style scoped>
.center-text {
  text-align: center;
  font-size: 20px;
}
</style>
